// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, LgPadding } from '@alto/design-system';
import { format, parse } from 'date-fns';
import React, { useCallback, useContext, useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { validateUser } from '~shared/actions/auth';
import { setInsuranceLoadingSeen } from '~shared/actions/onboarding';
import { clearValidatingUsersError } from '~shared/actions/uiOnboarding';
import touchID from '~shared/assets/images/touch_id@3x.png';
import {
  ONBOARD_STATE_MED_CONFIRMATION,
  ONBOARD_STATE_TO_ROUTE,
  type Route,
  VERIFY,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { DATE_FORMATS } from '~shared/helpers/date';
import { areCookiesDisabled } from '~shared/helpers/getDeviceID';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Values as VerificationDetails, Verify } from './Verify';
import CookieError from '~web/features/auth/components/CookieError';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';

type Props = {
  tempPassword?: string;
  onboardState?: string | null | undefined;
  onVerifySuccess: () => void;
  useExternalVerification?: boolean;
  externalUUID?: string;
  hubReferralID?: number;
  nextURL?: string;
};

export const VerifyActionSheet = ({
  onboardState,
  onVerifySuccess,
  tempPassword,
  useExternalVerification,
  externalUUID,
  hubReferralID,
  nextURL,
}: Props) => {
  const dispatch = useDispatchShared();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const state = useSelectorShared((state) => state);
  const nextRoute = (desiredOnboardRoute?: Route) => nextUrl(state, VERIFY, desiredOnboardRoute);
  const { trackPageView } = useAnalytics();

  const onVerify = async (data: VerificationDetails) => {
    const dob = format(
      parse(data.date_of_birth, DATE_FORMATS.DATE_INPUT_FORMAT, 0),
      DATE_FORMATS.YEAR_MONTH_DAY_DASHED,
    );
    const success = await dispatch(
      // eslint-disable-next-line import/no-deprecated
      validateUser({
        last_name: data.last_name,
        date_of_birth: dob,
        token: tempPassword,
        terms: data.terms,
        use_external_validation: useExternalVerification,
        external_uuid: externalUUID,
        hub_referral_id: hubReferralID,
      }),
    );

    if (!success) {
      return;
    }
    onVerifySuccess();

    if (useExternalVerification) {
      if (nextURL) {
        dispatch(push(nextURL));
      }
      return;
    }

    if (onboardState) {
      if (onboardState !== ONBOARD_STATE_MED_CONFIRMATION) {
        dispatch(setInsuranceLoadingSeen());
      }
      dispatch(push(nextRoute(ONBOARD_STATE_TO_ROUTE[onboardState])));
    } else {
      dispatch(push(nextRoute()));
    }
  };

  const handleClose = useCallback(() => {
    dispatch(clearValidatingUsersError());
    closeActionSheet();
  }, [closeActionSheet, dispatch]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__VERIFY_ACCOUNT_VIEWED,
      params: {
        is_progyny_rx_view: showProgyny,
      },
    });
  }, [showProgyny, trackPageView]);

  const cookiesDisabled = areCookiesDisabled();

  return (
    <ActionSheetV2
      title="Let's verify your identity"
      illustrationSrc={touchID}
      analyticsName={hubReferralID ? 'info request verify identity' : 'onboarding verify identity'}
      handleClose={handleClose}
    >
      <LgPadding>
        {cookiesDisabled ? <CookieError /> : null}
        <Verify
          onVerify={onVerify}
          disabled={cookiesDisabled}
          tempPassword={tempPassword}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
