import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { BREAKPOINTS, SPACING } from '@alto/design-library-tokens';
import { Body, Button, Card, H1, LgPadding, LgSpacing } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import {
  ADD_TO_SHIPMENT_MUTATION_KEY,
  CREATE_ORDER_MUTATION_KEY,
  CheckoutSplitShipmentHeader,
  CourierTipCard,
  DeliveryAddressCard,
  OrderBundlingPaymentSummary,
  PaymentMethodCard,
  PaymentSummary,
  SchedulingCard,
  SharpsContainerCard,
  SignatureConfirmationCard,
  getOrderBundlingCheckoutOrigin,
  useCheckoutValidationErrors,
  useCheckoutViewed,
  useOrderBundlingOptions,
  useSaveOrderText,
  useSignatureConfirmation,
} from '@alto/features';
import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import React from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getIsOrderBundling } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { getOrderAddressId } from '~shared/features/checkout/selectors/getOrder';
import { getIsPaymentMethodRequired } from '~shared/features/checkout/selectors/getPaymentBreakdown';
import { type CheckoutValidationErrors } from '~shared/features/checkout/types';
import { type AnalyticsProps as TipAnalyticsProps } from '~shared/features/courier-tips/AnalyticsProps';
import { PRICING_INFO } from '~shared/features/my-meds/constants';
import { type OrderPricing } from '~shared/features/pricing/types';
import { useQueryShipmentFeesAvailable } from '~shared/queries';
import { useIsMutating } from '~shared/react-query';
import { useSelectorShared } from '~shared/store';
import { CheckoutDeliverySummary } from './CheckoutDeliverySummary';
import { useGetItSoonerFlow } from './useGetItSoonerFlow';
import { ContentWrapper, FullFlexRow, Main, PageBackground } from '~web/components/Page';

type CheckoutProps = {
  readonly handleEditTipAmount: (tipAmount: number) => void;
  readonly handleSaveOrder: (hasValidationErrors: boolean, validationErrors: CheckoutValidationErrors) => void;
  readonly isEditingOrder: boolean;
  readonly isPriceUnconfirmed: boolean;
  readonly isTippable: boolean;
  readonly items: CartItem[];
  readonly orderIndex: number;
  readonly orderPricing: OrderPricing;
  readonly shipmentID: number | null | undefined;
  readonly tipAmount: number;
  readonly tipAnalyticsProps: TipAnalyticsProps;
};

export const Checkout = ({
  orderPricing,
  handleEditTipAmount,
  handleSaveOrder,
  isEditingOrder,
  isPriceUnconfirmed,
  isTippable,
  items,
  orderIndex,
  shipmentID,
  tipAmount,
  tipAnalyticsProps,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: CheckoutProps) => {
  const { value: isOrdersEndpointEnabled } = Experimentation.useFeatureFlag('orders_endpoint_web');
  const addressID = useSelectorShared(getOrderAddressId);
  const { deliveryMethods } = useCartDeliveryMethods();
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];
  const isSplitShipment = deliveryMethods.length > 1;
  const isFirstShipment = orderIndex === 0;
  const isLastShipment = orderIndex === deliveryMethods.length - 1;
  const { hasValidationErrors, validationErrors } = useCheckoutValidationErrors();

  const { openGetItSoonerFlow } = useGetItSoonerFlow();
  const { isPending: fetchShipmentFeesLoading } = useQueryShipmentFeesAvailable();
  const fetchDeliveryWindowsLoading = useSelectorShared((state) => state.ui.loading.fetchDeliveryWindowsLoading);
  const fetchOrderPricingLoading = useSelectorShared((state) => state.ui.loading.fetchOrderPricingLoading);
  const isSavingOrder = useSelectorShared((state) => state.ui.loading.saveOrderLoading);
  const isSaveOrderButtonDisabled =
    fetchDeliveryWindowsLoading || fetchOrderPricingLoading || fetchShipmentFeesLoading || isSavingOrder;
  const { hasInjectables, hasOnlyInjectableCompounds } = usePrescriptionsInCart();
  const isPaymentMethodRequired = useSelectorShared(getIsPaymentMethodRequired);
  const orderBundlingOrigin = getOrderBundlingCheckoutOrigin({ isEditingOrder });
  const { earliestUpcomingShipment } = useOrderBundlingOptions({
    address_id: addressID,
    origin: orderBundlingOrigin,
  });
  const isOrderBundling = useSelectorShared(getIsOrderBundling);
  const isAddToShipmentPending = !!useIsMutating({ mutationKey: ADD_TO_SHIPMENT_MUTATION_KEY });
  const isCreateOrderPending = !!useIsMutating({ mutationKey: CREATE_ORDER_MUTATION_KEY });
  const isLoading = isSavingOrder || isAddToShipmentPending || isCreateOrderPending;
  const hidePaymentMethod =
    !isPaymentMethodRequired && isOrderBundling && !earliestUpcomingShipment?.payment_method_needed;
  const saveOrderText = useSaveOrderText({ isLastShipment });
  const { homeToSignConfirmationRequired } = useSignatureConfirmation();

  useCheckoutViewed({ shipmentID, items, isEditingOrder, isSplitShipment: deliveryMethods.length > 1 });

  const isSelectAddressDisabled = (!isFirstShipment && isSplitShipment) || isSaveOrderButtonDisabled;
  const isSelectDateDisabled =
    (!isFirstShipment && isSplitShipment && !isOrdersEndpointEnabled) || isSaveOrderButtonDisabled;

  return (
    <PageBackground maxWidth={`${BREAKPOINTS.SM}px`}>
      <ContentWrapper>
        <FullFlexRow>
          <H1>Checkout</H1>
          {isSplitShipment ? <CheckoutSplitShipmentHeader orderIndex={orderIndex} /> : null}
        </FullFlexRow>
        {/* FYI this override is somewhat arbitrary - the sidebar containing checkout summary felt too skinny with the default 3/4 1/4 layout */}
        <Main wide>
          <DeliveryAddressCard disabled={isSelectAddressDisabled} />
          <SchedulingCard openGetItSoonerFlow={openGetItSoonerFlow} />
          {homeToSignConfirmationRequired ? <SignatureConfirmationCard /> : null}
          {hasInjectables && !hasOnlyInjectableCompounds && isLastShipment ? <SharpsContainerCard /> : null}
          {!isOrderBundling && isTippable ? (
            <>
              <CourierTipCard
                analyticsProps={tipAnalyticsProps}
                handleEditTipAmount={handleEditTipAmount}
                tipAmount={tipAmount}
              />
            </>
          ) : null}
          {hidePaymentMethod ? null : (
            <PaymentMethodCard
              deliveryMethodMode={deliveryMethod?.mode}
              disabled={isSelectDateDisabled}
            />
          )}
          <CheckoutDeliverySummary />
          {isOrderBundling ? (
            <OrderBundlingPaymentSummary
              showPaymentBreakdown
              origin={orderBundlingOrigin}
            />
          ) : (
            <PaymentSummary
              pricing={orderPricing}
              showZeroTipLineItem={isTippable}
              origin="Checkout"
            />
          )}
          <Button
            disabled={isSaveOrderButtonDisabled}
            loading={isLoading}
            testID="checkout-submit"
            onPress={() => {
              handleSaveOrder(hasValidationErrors, validationErrors);
            }}
          >
            {saveOrderText}
          </Button>
          {isPriceUnconfirmed ? (
            <>
              <LgSpacing />
              <Card title={PRICING_INFO.when_charged}>
                <LgPadding topPadding={SPACING.STATIC.NONE}>
                  <Body>{PRICING_INFO.wont_be_charged_until}</Body>
                </LgPadding>
              </Card>
            </>
          ) : null}
        </Main>
      </ContentWrapper>
    </PageBackground>
  );
};
