import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Description,
  InlineAlert,
  InputRadio,
  InputRadioGroup,
  LgPadding,
  LgSpacing,
  ListDescription,
} from '@alto/design-system';
import React, { useCallback, useContext, useEffect } from 'react';
import { selectAddress } from '~shared/actions/addresses';
import { updateOrder } from '~shared/actions/cart';
import { getAddress } from '~shared/features/addresses/selectors/getAddress';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { CONFIRM, NO_WAIVE_SIG_SELECTED, UNCONFIRMED, UNNECESSARY, WAIVE } from '~shared/features/checkout/constants';
import { getShowingValidationErrors } from '~shared/features/checkout/selectors/getCart';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { type HomeToSignConfirmation as HomeToSignConfirmationType } from '~shared/features/checkout/types';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { isCourier } from '~shared/helpers/order';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getOrigin from '~shared/lib/analytics/src/getOrigin';
import { getIsEditingOrderWithAssistant } from '~shared/selectors/alto-assistant/getIsEditingOrderWithAssistant';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { AddressFormActionSheet } from '../../../../address';
import { useCheckoutAddressErrors } from '../../../hooks/useCheckoutAddressErrors';

type HomeToSignConfirmationProps = {
  readonly isSignatureRequired?: boolean;
  readonly orderIndex: number;
};

export const homeToSignDecisionMapping: Record<string, string> = {
  [UNCONFIRMED]: 'unconfirmed',
  [UNNECESSARY]: 'unnecessary',
  [CONFIRM]: 'be home',
  [WAIVE]: 'waive signature',
};

export function HomeToSignConfirmation({ isSignatureRequired = false, orderIndex }: HomeToSignConfirmationProps) {
  const dispatch = useDispatchShared();
  const { home_to_sign_confirmation: homeToSignConfirmation, address_id: addressID } = useSelectorShared(getOrder);
  const { deliveryMethods } = useCartDeliveryMethods();
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const isEditingOrderWithAssistant = useSelectorShared(getIsEditingOrderWithAssistant);
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const address = useSelectorShared((state) => getAddress(state, { addressID }));
  const showingValidationErrors = useSelectorShared(getShowingValidationErrors);
  const { addressValidationErrors } = useCheckoutAddressErrors();
  const { trackEvent } = useAnalytics();
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];
  const deliveryMethodMode = deliveryMethod?.mode;

  useEffect(() => {
    if (isEditingOrder) return;
    if (isEditingOrderWithAssistant) return;

    dispatch(updateOrder({ home_to_sign_confirmation: undefined }));
  }, [dispatch, isEditingOrder, isEditingOrderWithAssistant]);

  const handleHomeToSignConfirmationChange = useCallback(
    (value: string) => {
      if (homeToSignConfirmation !== value) {
        dispatch(updateOrder({ home_to_sign_confirmation: value as HomeToSignConfirmationType }));
        trackEvent({
          event: EVENTS.CHECKOUT__HOME_TO_SIGN_DECISION,
          params: {
            'auto-selected': false,
            choice: homeToSignDecisionMapping[value],
            origin: getOrigin(),
          },
        });
      }

      if (value === WAIVE && address && !address?.safe_place && isCourier(deliveryMethodMode)) {
        dispatch(selectAddress(address));
        setActiveActionSheet(<AddressFormActionSheet isEditingSafePlace />);
      }
    },
    [address, dispatch, homeToSignConfirmation, setActiveActionSheet, trackEvent, deliveryMethodMode],
  );

  const hasWaiveSigValidationErrors =
    showingValidationErrors && addressValidationErrors.some((e) => e.key === NO_WAIVE_SIG_SELECTED);
  const showWarningForSplitShipmentCourier = !isEditingOrder && orderIndex > 0 && isCourier(deliveryMethodMode);

  return (
    <>
      <InputRadioGroup
        onValueChange={handleHomeToSignConfirmationChange}
        label="address"
        defaultValue={isEditingOrder && homeToSignConfirmation ? homeToSignConfirmation : undefined}
        value={homeToSignConfirmation}
      >
        <InputRadio
          testID="home-to-sign-confirmation"
          label={
            isSignatureRequired
              ? 'Someone will be home to sign'
              : 'Do not leave package, someone will be present to receive delivery'
          }
          radioPlacement="left"
          value={CONFIRM}
        />
        <InputRadio
          label={isSignatureRequired ? 'Waive signature requirement' : 'Courier may leave package in safe location'}
          descriptions={[
            <ListDescription key="waive">
              {isSignatureRequired
                ? "It's okay to leave this package. I accept responsibility for lost or stolen packages."
                : 'I accept responsibility for lost or stolen packages.'}
            </ListDescription>,
          ]}
          radioPlacement="left"
          value={WAIVE}
        />
      </InputRadioGroup>
      {hasWaiveSigValidationErrors ? (
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <InlineAlert type="error">
            <Description>You must select an option</Description>
          </InlineAlert>
        </LgPadding>
      ) : null}
      {showWarningForSplitShipmentCourier ? (
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          <InlineAlert type="warning">
            <Description>
              {isSignatureRequired
                ? 'Signature waiving only applies to the delivery from Alto Pharmacy.'
                : 'You can choose to not be home for the courier delivery by adding a safe place.'}
            </Description>
          </InlineAlert>
        </LgPadding>
      ) : null}
      {!hasWaiveSigValidationErrors && !showWarningForSplitShipmentCourier ? <LgSpacing /> : null}
    </>
  );
}
