import { ModeTypeMap } from '@alto/deliver_api/types/delivery_methods/v1/mode_type';
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetToast,
  ActionSheetV2,
  AltoSpinningLoader,
  Body,
  Button,
  Description,
  InlineAlert,
  InputAutocomplete,
  LgPadding,
  LgSpacing,
  Link,
  MdPadding,
  Row,
  SmSpacing,
  Toast,
  ToastContext,
  XsSpacing,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, type SubmitErrorHandler, type SubmitHandler, useController, useForm } from 'react-hook-form';
import { Linking, type TextInput } from 'react-native';
// eslint-disable-next-line import/no-deprecated
import { createAddress, deleteAddress, deselectAddress, selectAddress, updateAddress } from '~shared/actions/addresses';
// eslint-disable-next-line import/no-deprecated
import { fetchServiceAvailability } from '~shared/actions/auth';
import { updateOrder } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
import { ALTO_LOCATIONS_URL } from '~shared/constants';
import { getAddress } from '~shared/features/addresses/selectors/getAddress';
import { getAddressDeleteLoading } from '~shared/features/addresses/selectors/getAddresses';
import getSelectedAddress from '~shared/features/addresses/selectors/getSelectedAddress';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { type ApiResponseRow } from '~shared/features/transfers/types';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { getErrorMessage } from '~shared/helpers/helper';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type APIError } from '~shared/types';
import { ConfirmDeleteActionSheet } from '../../action-sheet/components/ConfirmDeleteActionSheet';
import { useCheckoutAddressInfoKeys } from '../../checkout/hooks/useCheckoutAddressInfoKeys';
import { useGetDeliveryMethodsByStateAndZip } from '../../delivery-methods/queries/queries';
import { ADDRESS_DELIVERY_METHOD_DESCRIPTIONS, ADDRESS_FORM_ERROR_MESSAGES } from '../constants';
import { AddressFormAdditionalFields } from './AddressFormAdditionalFields';
import {
  type GooglePlacesValue,
  formatApiResponseRow,
  googlePlacesAddressDataSource,
  googlePlacesAddressDataSourceV2,
} from './data-source/googlePlacesDataSource';
import { transformToFullAddress } from './data-source/helpers';
import { type AddressFormFields, type AddressFormFieldsOnSubmit, cleanFormFields } from './helpers';

type Props = {
  readonly isEditingSafePlace?: boolean;
  readonly origin?: OriginName;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AddressFormActionSheet = ({ isEditingSafePlace = false, origin }: Props) => {
  // There's no way to currently show a toast above a react-native modal.
  // As a workaround, we are manually showing the toast above the modal content.
  const [toast, setToast] = useState('');
  const { value: useV2GoogleDataSource } = Experimentation.useFeatureFlag('reduced_google_places_api_address_form');
  const googlePlacesDataSource = useV2GoogleDataSource
    ? googlePlacesAddressDataSourceV2
    : googlePlacesAddressDataSource;
  const dispatch = useDispatchShared();
  const { closeActionSheet, setActiveActionSheet, goToPreviousActionSheet } = useContext(ActionSheetContext);
  const { keys: addressInfoKeys } = useCheckoutAddressInfoKeys();
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const isEditing = isEditingOrder || isEditingSafePlace;
  const { addToast } = useContext(ToastContext);
  const methods = useForm<AddressFormFields>();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const deleteLoading = useSelectorShared(getAddressDeleteLoading);
  const serviceAvailabilityLoading = useSelectorShared((state) => state.ui.loading.fetchServiceAvailabilityLoading);
  const address = useSelectorShared(getSelectedAddress);
  const defaultAddress = useSelectorShared(getDefaultAddress);
  const order = useSelectorShared(getOrder);
  const orderAddress = useSelectorShared((state) => getAddress(state, { addressID: order.address_id }));
  const editingMode = !!address;
  const [isServiceAvailable, setIsServiceAvailable] = useState(editingMode ? true : undefined); // is address from googlePlacesAutocomplete is within Alto's coverage
  const inputAutocompleteRef = useRef<TextInput | null>(null);

  const handleOpenAddressForm = () => {
    if (orderAddress) {
      dispatch(selectAddress(orderAddress));
    } else if (defaultAddress) {
      dispatch(selectAddress(defaultAddress));
    }
    setActiveActionSheet(
      <AddressFormActionSheet
        isEditingSafePlace
        origin={origin}
      />,
    );
  };

  const handleWaiveWithoutSafePlaceError = () => {
    if (addressInfoKeys.has_controls) return; // do not reset home to sign confirmation for controlled meds

    dispatch(updateOrder({ home_to_sign_confirmation: null }));
    addToast(
      <Toast
        variant="error"
        dismissible
        duration={10000}
      >
        {addressInfoKeys.expensive
          ? 'You cannot waive signature requirements without updating your delivery instructions.'
          : 'If you will not be present, you must indicate that packages may be left at this address.'}
        <XsSpacing />
        <Link
          color={COLORS.TEXT_COLORS.WHITE}
          onPress={handleOpenAddressForm}
          underline
        >
          Add a safe place.
        </Link>
      </Toast>,
    );
  };

  const addressController = useController({
    control,
    name: 'google_street_address',
    rules: {
      required: ADDRESS_FORM_ERROR_MESSAGES.google_street_address,
    },
    defaultValue: {
      name: address?.street_address_1,
      street_address: address?.street_address_1,
      city: address?.city,
      state: address?.state,
      zip: address?.zip,
      neighborhood: undefined,
      phone: undefined,
    },
  });

  const { availableDeliveryMethods } = useGetDeliveryMethodsByStateAndZip(
    addressController.field.value?.state || '',
    addressController.field.value?.zip || '',
  );
  const deliveryMode = availableDeliveryMethods?.at(0)?.mode;
  const isCourier = deliveryMode === ModeTypeMap.COURIER;
  const isMail = deliveryMode === ModeTypeMap.MAIL;

  useEffect(() => {
    if (!editingMode) {
      inputAutocompleteRef.current?.focus();
    }

    return () => {
      inputAutocompleteRef.current = null;
    };
  }, [editingMode]);

  useEffect(() => {
    return () => {
      dispatch(deselectAddress());
    };
  }, [dispatch]);

  const handleServiceAvailabilityCheck = async (state: string | null | undefined) => {
    try {
      // eslint-disable-next-line import/no-deprecated
      const response = await dispatch(fetchServiceAvailability(null, state));
      if ('available' in response) {
        setIsServiceAvailable(response.available);
      }
    } catch (e) {
      addToast(<Toast>{getErrorMessage(e as APIError)}</Toast>);
    }
  };

  const changeAddress = (data: ApiResponseRow<GooglePlacesValue>[]) => {
    // The implementation of InputAutocomplete on desktop web is different, as the select data includes both old and new address values,
    // unlike other platforms where data contains only a single new address value.
    const googleAddress = data[data.length - 1]?.value;
    addressController.field.onChange(googleAddress);
    handleServiceAvailabilityCheck(googleAddress.state);
  };

  const changeAddressV2 = async (data: ApiResponseRow<GooglePlacesValue>[]) => {
    // The implementation of InputAutocomplete on desktop web is different, as the select data includes both old and new address values,
    // unlike other platforms where data contains only a single new address value.
    const googleAddress = await transformToFullAddress(data[data.length - 1]);
    addressController.field.onChange(googleAddress);
    handleServiceAvailabilityCheck(googleAddress?.state);
  };

  const onInvalid: SubmitErrorHandler<AddressFormFields> = (data) => {
    const errorMessage = Object.values(data)
      .map((data) => data?.message)
      .join('\n');
    addToast(<Toast variant="error">Whoops! {errorMessage}</Toast>);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSubmit: SubmitHandler<AddressFormFields> = async (data) => {
    const fields = cleanFormFields(data, address?.id);
    if (editingMode) {
      // eslint-disable-next-line import/no-deprecated
      const { success, json } = await dispatch(updateAddress(fields as AddressFormFieldsOnSubmit & { id: number }));
      if (success) {
        closeActionSheet();
        reset();

        if (origin === 'med list' && order.address_id !== address.id) {
          dispatch(updateOrder({ address_id: address.id }));
          // eslint-disable-next-line import/no-deprecated
          dispatch(fetchNextAvailableDates({ addressID: address.id }));
        }

        if (origin === 'checkout') {
          // the first address in the json response is either
          // (1) new address with different id if certain, immutable fields are modified
          // (2) same address with the same id for other fields that can be modified
          const addressID = json[0].id ?? defaultAddress?.id;
          dispatch(updateOrder({ address_id: addressID, home_to_sign_confirmation: undefined }));
          // TODO: remove once NAD hook ready
          // eslint-disable-next-line import/no-deprecated
          dispatch(fetchNextAvailableDates({ addressID: address.id }));
        }
      } else {
        setToast('Unable to update address. Please try again or message support if the problem persists.');
      }
    } else {
      // Remove safe place instructions when no safe place
      if (fields.safe_place === false) {
        fields.safe_place_instructions = '';
      }
      // eslint-disable-next-line import/no-deprecated
      const { success, newAddress } = await dispatch(createAddress(fields));
      if (success) {
        closeActionSheet();
        reset();

        if (newAddress && origin === 'med list') {
          dispatch(updateOrder({ address_id: newAddress.id }));
        }

        if (newAddress && origin === 'checkout') {
          dispatch(updateOrder({ address_id: newAddress.id, home_to_sign_confirmation: undefined }));
          // TODO: remove once NAD hook ready
          // eslint-disable-next-line import/no-deprecated
          dispatch(fetchNextAvailableDates());
        }
      } else {
        setToast('Unable to create address. Please try again or message support if the problem persists.');
      }
    }

    if (addressInfoKeys.has_controls) {
      return; // do not show any toast messages for orders containing controlled substances
    }

    const removedSafePlace = !fields.safe_place && order.home_to_sign_confirmation === 'WAIVE';
    if (removedSafePlace) {
      handleWaiveWithoutSafePlaceError();
    }

    if (isEditing && fields.safe_place && fields.safe_place_instructions) {
      addToast(
        <Toast variant="success">Successfully added a safe place! You do not need to be home for this delivery.</Toast>,
      );
    }
  };

  const handleDelete = () => {
    if (address?.id) {
      const onConfirm = async () => {
        // eslint-disable-next-line import/no-deprecated
        const success = await dispatch(deleteAddress(address.id));
        if (success) {
          closeActionSheet();
          reset();

          if (origin === 'checkout' && order.address_id === address.id) {
            dispatch(updateOrder({ address_id: defaultAddress?.id, home_to_sign_confirmation: undefined }));
          }
        } else {
          goToPreviousActionSheet();
          setToast('Unable to delete address. Please try again or message support if the problem persists.');
        }
      };
      setActiveActionSheet(
        <ConfirmDeleteActionSheet
          analyticsName="confirm delete address"
          description={address.street_address_1 || 'this address'}
          onConfirm={onConfirm}
        />,
        { shouldRetainPreviousActionSheet: true },
      );
    }
  };

  // to avoid a nested ternary define buttons here
  let buttons:
    | [React.ReactElement<typeof Button>]
    | [React.ReactElement<typeof Button>, React.ReactElement<typeof Button>]
    | undefined = undefined;

  if (isServiceAvailable) {
    buttons = [
      <Button
        key="save-address-button"
        disabled={isSubmitting || deleteLoading}
        loading={isSubmitting}
        label={editingMode ? 'Save' : 'Add address'}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={handleSubmit(onSubmit, onInvalid)}
      />,
    ];
    if (editingMode) {
      buttons.push(
        <Button
          key="delete-address-button"
          variant="danger"
          type="tertiary"
          label="Delete Address"
          loading={deleteLoading}
          disabled={deleteLoading || isSubmitting}
          onPress={handleDelete}
        />,
      );
    }
  }

  const isWaivingWithoutSafePlace = !orderAddress?.safe_place && order.home_to_sign_confirmation === 'WAIVE';
  const showAddSafePlaceWarning = isWaivingWithoutSafePlace && isEditing;

  const handleClose = () => {
    if (showAddSafePlaceWarning) {
      handleWaiveWithoutSafePlaceError();
    }
  };

  return (
    <FormProvider {...methods}>
      <ActionSheetV2
        title={editingMode ? 'Edit address' : 'Add new address'}
        buttons={buttons}
        handleClose={handleClose}
      >
        <LgPadding topPadding={SPACING.STATIC.NONE}>
          {showAddSafePlaceWarning ? (
            <>
              <InlineAlert type="warning">
                <Description>
                  Please update your delivery instructions to indicate that packages may be left.
                </Description>
              </InlineAlert>
              <SmSpacing />
            </>
          ) : null}
          <InputAutocomplete
            label="Address"
            title={editingMode ? 'Edit address' : 'Add new address'}
            onSelectCallback={useV2GoogleDataSource ? changeAddressV2 : changeAddress}
            dataSource={googlePlacesDataSource}
            placeholder="Search for your address"
            initialValues={formatApiResponseRow(addressController.field.value, address?.id)}
            error={addressController.fieldState.error?.message}
            onRef={inputAutocompleteRef}
            required
          />
          {isServiceAvailable && !serviceAvailabilityLoading && (isMail || isCourier) ? (
            <>
              <XsSpacing />
              <Description>
                {isCourier
                  ? ADDRESS_DELIVERY_METHOD_DESCRIPTIONS.courier
                  : ADDRESS_DELIVERY_METHOD_DESCRIPTIONS.mail_only}
              </Description>
            </>
          ) : null}
          {!!addressController.fieldState.error && <Body>{addressController.fieldState.error?.message}</Body>}
          {serviceAvailabilityLoading ? (
            <MdPadding>
              <Row center>
                <AltoSpinningLoader />
                <XsSpacing />
                <Body>Verifying availability...</Body>
              </Row>
            </MdPadding>
          ) : null}
          {!serviceAvailabilityLoading && isServiceAvailable === false ? (
            <MdPadding
              leftPadding={SPACING.STATIC.NONE}
              rightPadding={SPACING.STATIC.NONE}
            >
              <InlineAlert type="error">
                <Description>{ADDRESS_FORM_ERROR_MESSAGES.out_of_zone_address}</Description>
                <XsSpacing />
                <Link
                  onPress={() => {
                    Linking.openURL(ALTO_LOCATIONS_URL);
                  }}
                  textSize="mini"
                >
                  Where does Alto deliver?
                </Link>
              </InlineAlert>
            </MdPadding>
          ) : null}
        </LgPadding>
        {isServiceAvailable && !serviceAvailabilityLoading ? (
          <AddressFormAdditionalFields
            address={address}
            isEditingSafePlace={isEditingSafePlace}
            showDeliveryInstructions={isCourier}
          />
        ) : null}
        <LgSpacing />
        {toast ? (
          <ActionSheetToast
            variant="error"
            dismissible
            onHide={() => {
              setToast('');
            }}
          >
            {toast}
          </ActionSheetToast>
        ) : null}
      </ActionSheetV2>
    </FormProvider>
  );
};
